<template>
  <div class="content">
    <header>
      <section class="head_goback" @click="goback">
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <polyline
            points="12,18 4,9 12,0"
            style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 2"
          />
        </svg>
      </section>
      <section class="title_head ellipsis">
        <span class="title_text">扫描设备</span>
      </section>
    </header>
    <div class="Detail">
      <div id="code">
        <div
          style="
            width: 16rem;
            height: 12.8rem;
            background-color: #000;
            margin: 15% auto 5%;
            text-align: center;
          "
        >
          <div class="qrcode">
            <qrcode
              :qrcode="qrcode"
              v-show="qrcode"
              :camera="camera"
              :torchActive="torchActive"
              @onDecode="onDecode"
              @onInit="onInit"
            />
          </div>
        </div>
      </div>
      <p style="text-align: center">将二维码放入框内，即可自动扫描</p>
      <div style="text-align: center" class="sweep" @click="clickCode">
        开始扫码
      </div>
      <div style="text-align: center" class="start" @click="next">
        识别失败，手动录入
      </div>
      <!-- <div class="code-button"> -->
        <div class="start" @click="switchCamera">相机反转</div>
        <div class="start" @click="ClickFlash">打开手电筒</div>
        <div class="start" @click="turnCameraOff">关闭相机</div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SaoYiSao",
  components: {
    qrcode: () => import("@/components/QrcodeReader"),
  },
  data() {
    return {
      qrcode: false,
      torchActive: false,
      camera: "off",
    };
  },
  methods: {
    goback() {
      this.$router.back();
    },
    next() {
      this.$router.back();
    },
    // 打开相机
    clickCode() {
      // camera:: 'rear'--前摄像头，'front'后摄像头，'off'关闭摄像头，会获取最后一帧显示，'auto'开始获取摄像头
      this.qrcode = true;
      this.camera = "rear";
    },
    // 扫码结果回调
    onDecode(result) {
      console.log(result);
      this.turnCameraOff();
      // this.$router.replace('/saoyisao')
      this.$router.replace({
          path: '/profile',
          query: {
            result: result
          }
      })
    },
    // 相机反转
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
        default:
          this.$toast("错误");
      }
    },
    // 关闭相机？？？？？？
    turnCameraOff() {
      this.camera = "off";
      this.qrcode = false;
    },
    // 打开手电筒
    ClickFlash() {
      switch (this.torchActive) {
        case true:
          this.torchActive = false;
          break;
        case false:
          this.torchActive = true;
          break;
        default:
          this.$toast("错误");
      }
    },

    // 检查是否调用摄像头
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "StreamApiNotSupportedError") {
        } else if (error.name === "NotAllowedError") {
          this.errorMessage = "Hey! I need access to your camera";
        } else if (error.name === "NotFoundError") {
          this.errorMessage = "Do you even have a camera on your device?";
        } else if (error.name === "NotSupportedError") {
          this.errorMessage =
            "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
        } else if (error.name === "NotReadableError") {
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.errorMessage =
            "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
        } else {
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
        }
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.content {
  background-color: #000;
  color: white;
  height: 100vh;
  overflow: hidden;

  position: relative;
  z-index: 9;
}
.content header {
  background-color: #000;
  height: 2.00533rem;
  width: 100%;
  text-align: center;
  line-height: 2.00533rem;
  display: flex;
  /*position: fixed;*/
  top: 0;
  z-index: 99999;
}
.content header /deep/ .head_goback {
  padding-top: 0.1rem;
  width: 0.6rem;
  height: 0.8533rem;
  padding-left: 0.2rem;
}
.content header /deep/ .title_head {
  width: 92%;
  text-align: center;
  color: white;
  font-size: 0.8533rem;
  letter-spacing: 2px;
}
.content .Detail {
  width: 96%;
  margin: 0 auto;
}
.content .Detail .session .name {
  margin: 0.4rem 0;
  font-size: 0.4rem;
  color: #333333;
  font-weight: bold;
}
.content .Detail .session .useTutorials {
  background-color: #3998f7;
  border-radius: 5px;
}
.content .Detail .session .desc {
  margin: 0.4rem 0;
  font-size: 0.4rem;
  color: #333333;
}
.content .Detail .session .desc .title {
  font-weight: bold;
}
.content .Detail .session .desc .intro {
  margin-top: 0.1rem;
}
.content .Detail p {
  font-size: 0.72rem;
}
.content .Detail .sweep {
  text-align: center;
  margin: 0.1rem auto;
  width: 13.8667rem;
  height: 1.7067rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.024rem;
  font-size: 0.768rem;
  position: relative;
  z-index: 9999;
  color: white;
  background: #ff8c99;
  box-shadow: 0px 6px 9px 0px rgba(255, 140, 153, 0.57);
  margin-top: 2rem;
}
.content .start {
  text-align: center;
  margin: 0 auto;
  width: 13.8667rem;
  height: 1.7067rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.024rem;
  font-size: 0.768rem;
  background: white;
  border: 2px solid #ffa9a3;
  color: #ffa9a3;
  margin-top: 0.64rem;
}
.qrcode{
  width: 254px;
  height: 254px;
  border: #f00 solid 1px;
  overflow: hidden;
}
</style>
